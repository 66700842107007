import { useCallback, useMemo } from 'react';

import { isFunction } from 'lodash-es';
import { SetterOrUpdater, atom, useRecoilState } from 'recoil';

import { UserRole } from 'api';

import { USER_STATE_KEY, defaultUser, userValidationScehma } from './const';
import { User, UserStatus } from './types';

const userState = atom<User>({
  key: USER_STATE_KEY,
  default: defaultUser,
  effects: [
    // initialize state
    ({ setSelf }) => {
      const persistedUserStateStr = localStorage.getItem(USER_STATE_KEY);

      // CASE 1. 로컬 스토리지에 사용자 정보가 없다면 defaultUser로 초기화
      if (persistedUserStateStr === null) {
        localStorage.setItem(USER_STATE_KEY, JSON.stringify(defaultUser));
        setSelf(defaultUser);
        return;
      }

      let persistedUserState: unknown;

      try {
        persistedUserState = JSON.parse(persistedUserStateStr);
      } catch (error) {
        // CASE 2. 로컬 스토리지에 사용자 정보가 있지만 파싱에 실패한 경우 defaultUser로 초기화
        localStorage.setItem(USER_STATE_KEY, JSON.stringify(defaultUser));
        setSelf(defaultUser);
        return;
      }

      const isValidUser = userValidationScehma.isValidSync(persistedUserState);

      if (isValidUser) {
        // CASE 3. 로컬 스토리지에 사용자 정보가 있고, 유효한 값일 경우 해당 정보로 초기화
        setSelf(persistedUserState as User);
      } else {
        // CASE 4. 로컬 스토리지에 사용자 정보가 있지만 유효한 값이 아닐 경우 defaultUser로 초기화
        localStorage.setItem(USER_STATE_KEY, JSON.stringify(defaultUser));
        setSelf(defaultUser);
      }
    },
    // sync state on multiple tabs
    ({ setSelf }) => {
      const handleStorage = () => {
        const persistedUserStateStr = localStorage.getItem(USER_STATE_KEY);

        if (persistedUserStateStr === null) {
          setSelf(defaultUser);
          return;
        }

        let persistedUserState: unknown;

        try {
          persistedUserState = JSON.parse(persistedUserStateStr);
        } catch (error) {
          setSelf(defaultUser);
          return;
        }

        const isValidUser = userValidationScehma.isValidSync(persistedUserState);

        if (isValidUser) {
          setSelf(persistedUserState as User);
        } else {
          setSelf(defaultUser);
        }
      };

      window.addEventListener('storage', handleStorage);

      return () => {
        window.removeEventListener('storage', handleStorage);
      };
    },
  ],
});

const useUser = () => {
  const [user, setUser] = useRecoilState(userState);

  const _setUser = useCallback<SetterOrUpdater<User>>(
    (valOrUpdater) => {
      setUser((prev) => {
        const nextState = isFunction(valOrUpdater) ? valOrUpdater(prev) : valOrUpdater;
        localStorage.setItem(USER_STATE_KEY, JSON.stringify(nextState));
        return nextState;
      });
    },
    [setUser]
  );

  const isAuthorizedUser = useMemo(() => {
    const loggedInStatus = user.status === UserStatus.LoggedIn;
    return loggedInStatus && userValidationScehma.isValidSync(user);
  }, [user]);

  const canAccessMediaApprovalPage = useMemo(() => {
    return isAuthorizedUser && user.roles.includes(UserRole.ApproveMedia);
  }, [isAuthorizedUser, user.roles]);

  const canUpdateTags = useMemo(() => {
    return isAuthorizedUser && user.roles.includes(UserRole.UpdateTags);
  }, [isAuthorizedUser, user.roles]);

  const monitoringOnly = useMemo(() => {
    return isAuthorizedUser && user.roles.includes(UserRole.Monitoring);
  }, [isAuthorizedUser, user.roles]);

  return {
    user,
    setUser: _setUser,
    isAuthorizedUser,
    canAccessMediaApprovalPage,
    canUpdateTags,
    monitoringOnly,
  };
};

export default useUser;
